<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :max-width="dialogWidth"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="doneAdding"
  >
    <v-card
      v-touch:swipe.left="doneAdding"
      tile
      class="d-flex flex-column noscroll charcoal"

    >
      <BaseCloseDialogHeader color="black" heading="" @cancel="doneAdding" />
      <template v-if="!loaded">
        <v-card flat class="mt-10 pt-10 d-flex flex-column transparent">
          <v-spacer />
          <v-card-actions>
            <v-spacer />
            <v-img
              class="opacity-40 rounded-xl"
              :src="foodGauge"
              :width="isPhone ? 200 : 30"
              contain
              alt="Loading Daily Energy"
            />

            <v-spacer />
          </v-card-actions>
          <v-card-text> </v-card-text>
          <BaseLoading label="Loading Daily Energy" class="py-5" />
        </v-card>
      </template>
      <template v-else>
        <template v-if="tdee?.metric > 0">
          <v-card class="transparent pa-0 mt-n5" flat>
            <v-sheet rounded class="transparent px-1">
              <v-card-actions :class="textSizeSmall + ' pa-0 mb-1'">
                <v-spacer />
                <v-icon class="" size="50" color="paper" @click="previousDay">
                  mdi-chevron-left
                </v-icon>
                <span class="paper--text">
                  Energy for {{ prettyShortDate(now(offset)) }}
                </span>
                <v-icon class="mt-n" size="50" color="paper" @click="nextDay">
                  mdi-chevron-right
                </v-icon>
                <v-spacer />
              </v-card-actions>
              <v-card-actions class="pa-0 mx-2 mt-n3">
                <v-progress-linear
                  v-if="diff <= 0"
                  height="45"
                  rounded
                  background-color="paper"
                  color="progressActive lighten-1"
                  :value="currentPercentage"
                  class="opacity-90"
                >
                  <template v-slot:default="{ value }">
                    <v-card-text class="charcoal--text text-center pa-0 ">
                      <span class="font-weight-bold">
                        {{ Math.round(totalIn) }} kCal of
                        {{ Math.round(totalOut) }} kCal, {{ value }}%
                      </span>
                      <v-spacer />
                      <span class="font-weight-bold">
                        {{ Math.abs(diff) }} kCal deficit,
                        {{ twoDigits(Math.abs((diff * 7) / 3300)) }}
                        lbs/week weight loss.
                      </span>
                    </v-card-text>
                  </template>
                </v-progress-linear>
                <v-progress-linear
                  v-else
                  class="opacity-90"
                  height="45"
                  rounded
                  background-color="progressActive"
                  color="orange"
                  :value="currentPercentage - 100"
                >
                  <template v-slot:default="{ value }">
                    <v-card-text class="charcoal--text text-center pa-0">
                      <span class="font-weight-bold">
                        {{ value }}% excess over
                        {{ Math.round(totalOut) }} kCal, total of
                        {{ 100 + value }}%
                      </span>
                      <v-spacer />
                      <span class="font-weight-bold">
                        {{ diff }} kCal excess,
                        {{ twoDigits(Math.abs((diff * 7) / 3300)) }}
                        lbs/week weight gain.
                      </span>
                    </v-card-text>
                  </template>
                </v-progress-linear>
              </v-card-actions>
              <v-row dense class="mx-1">
                <v-col cols="6">
                  <v-card flat class="pa-0 transparent charcoalTileMenu">
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn
                        height="50"
                        large
                        title="Add Meal"
                        block
                        text
                        class="charcoalTileMenu"
                        color="paper"
                        @click="showAddMealDialog = true"
                      >
                        <v-icon color="paper" class="mr-1" >
                          mdi-food-fork-drink
                        </v-icon>
                        <template v-if="energyIn">
                          {{ energyIn.calories }}
                        </template>
                        <span v-else>
                          0
                        </span>
                        <span class="caption ml-1">
                          Kcal
                        </span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card flat class="pa-0 transparent charcoalTileMenu">
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn
                        height="50"
                        large
                        title="Add Activity"
                        text
                        block
                        class="charcoalTileMenu"
                        color="paper"
                        @click="showActivitiesDialog = true"
                      >
                      <v-icon color="paper" class="mr-1" >
                          mdi-run
                        </v-icon>
                        <template v-if="energyOut">
                          {{ Math.floor(energyOut.calories_total) }}
                        </template>
                        <span v-else>
                          0
                        </span>
                        <span class="caption ml-1">
                          Kcal
                        </span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense class="mb-1 mt-n2">
                <v-col cols="6">
                  <v-card-actions
                    class="caption pa-0 silver--text"
                    v-if="meals?.length > 0"
                    @click="showDailyMeals"
                  >
                    <v-icon large color="silver" class="ml-1">
                      mdi-arrow-top-left-thick
                    </v-icon>

                    FOOD LOG
                    <v-spacer />
                  </v-card-actions>
                </v-col>
                <v-col cols="6">
                  <v-card-actions
                    class="caption pa-0 silver--text"
                    v-if="activities?.length > 0"
                    @click="showDailyActivities"
                  >
                    <v-spacer />
                    ACTIVITY LOG
                    <v-icon large color="silver" class="ml-1">
                      mdi-arrow-top-right-thick
                    </v-icon>
                  </v-card-actions>
                </v-col>
              </v-row>
              <v-card-actions class="px-2 py-0 mt-n2">
                <v-spacer />
                <v-btn
                  height="50"
                  x-large
                  block
                  title="Supplements"
                  plain
                  color="paper"
                  class="charcoalTile"
                  @click="showAddSupplementsDialog = true"
                >
                  <v-icon color="paper" large>
                    mdi-pill-multiple
                  </v-icon>
                  <span class=" ml-1">
                    Supplements
                  </span>
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-sheet>
          </v-card>
          <template v-if="energyIn != null">
            <v-sheet class="rounded-lg pa-2 pt-0  mx-10  charcoal ">
              <v-card-actions
                :class="textSizeLarge + ' pb-0 hybrid--text text--lighten-3'"
              >
              <span :class="textSize">
                  FATS
                </span>
                <v-spacer />
                {{ nvl(energyIn.fats, 0) }}<span class="caption ml-1">g</span>
              </v-card-actions>
              <v-card-actions
                :class="textSizeLarge + ' mt-n2 py-0 progressActive--text '"
              >
                <span :class="textSize">
                  PROTEINS
                </span>
                <v-spacer />
                {{ nvl(energyIn.proteins, 0) }}<span class="caption ml-1">g</span>
              </v-card-actions>
              <v-card-actions :class="textSizeLarge + ' mt-n2 py-0  gold--text'">
                <span :class="textSize">
                  CARBS
                </span>
                <v-spacer />
                {{ nvl(energyIn.carbs, 0) }}<span class="caption ml-1">g</span>
              </v-card-actions>
              <v-card-actions
                :class="
                  textSizeSmall + ' mt-n2 py-0 charcoal--text text--lighten-3'
                "
              >
              <span :class="textSize">
                  Fiber
                </span>
                <v-spacer />
                {{ nvl(energyIn.fiber, 0) }} g
              </v-card-actions>
              <v-card-actions
                v-if="nvl(energyIn.sugara, 0) > 0"
                :class="
                  textSizeSmall + ' mt-n1 py-0 charcoal--text text--lighten-3'
                "
              >
                <span class="mx-2">
                  Sugar Alcohol
                </span>
                <v-spacer />
                {{ energyIn.sugara }} g
              </v-card-actions>
            </v-sheet>
            <v-card-actions class="pa-0 mt-5">
              <v-spacer />
            <MyPieChart
              v-if="loaded"
              :height="isPhone ? 230 : 220"
              class=""
              :chartdata="chartdata"
              :options="options"
              :key="switchnum"
            />
            <v-spacer />
          </v-card-actions>
          </template>
          <v-spacer />
          <template v-if="matches?.length > 0">
            <v-card-text class="text-center silver--text pa-0 py-1">
              DIET MATCHES
            </v-card-text>
            <v-card-actions class="caption pa-0 mb-1 ">
              <v-spacer />
              <span
                class="  charcoalTileMenu pa-2  mx-1 mb-2 rounded text-center paper--text "
                v-for="(diet, index) in matches.slice(0, 2)"
                :key="index"
              >
                <template v-if="index <= 1">
                  {{ diet.name }} {{ 100 - diet.diff }}%
                </template>
              </span>
              <v-spacer />
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <template v-if="loaded">
            <v-sheet class="charcoal charcoalTileMenu mx-2" rounded>
              <v-card-text class="paper--text px-0 mx-2 ">
                The Energy screen is not yet available because we are missing
                some required information.
                <v-spacer />
              </v-card-text>
              <v-card-text class="silver--text pa-0 px-2">
                Click the button below to fix this.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  large
                  title="Enter Information"
                  block
                  tonal
                  class="ml-n2 black--text"
                  color="progressActive"
                  @click="showWeightPrompt = true"
                >
                  <v-icon large>
                    mdi-plus-circle-outline
                  </v-icon>
                  Enter Information
                </v-btn>
              </v-card-actions>
            </v-sheet>
          </template>
        </template>
      </template>

      <WeightPrompt :show="showWeightPrompt" @done="done" class="pt-10" />
    </v-card>
    <AddMealDialog
      :day="now(offset)"
      :show="showAddMealDialog"
      @done="reloadDaily"
      @cancel="showAddMealDialog = false"
    />
    <Activities
      v-if="loggedIn"
      :show="showActivitiesDialog"
      @saved="reloadDaily"
      @cancel="showActivitiesDialog = false"
    />
    <v-dialog
      v-model="showFoodDetailsDialog"
      :fullscreen="isPhone"
      :max-width="dialogWidth"
      persistent
      overlay-opacity="0.97"
      scrollable
      @keydown.esc="cancel"
    >
      <v-card
        tile
        class="charcoal d-flex flex-column overflow-y-auto noscroll-x"
      >
        <BaseCloseDialogHeader
          heading=""
          color="charcoal paper--text"
          @cancel="showFoodDetailsDialog = false"
        />
        <v-card-text class="pa-0">
          <FoodDetails
            :day="now(offset)"
            @deleted_meal="refreshPage"
            :show="showFoodDetailsDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showActivitiesDetailsDialog"
      :fullscreen="isPhone"
      max-width="1024"
      persistent
      overlay-opacity="0.97"
      scrollable
      eager
      @keydown.esc="cancel"
    >
      <v-card
        tile
        class="charcoal d-flex flex-column overflow-y-auto noscroll-x"
      >
        <BaseCloseDialogHeader
          :heading="'Daily Activities - ' + prettyShortDate(now(offset))"
          color="charcoal paper--text"
          @cancel="showActivitiesDetailsDialog = false"
        />
        <v-card-text class="pa-0">
          <ActivitiesDetails
            :show="showActivitiesDetailsDialog"
            :day="now(offset)"
            @deleted_activity="refreshPage"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <AddSupplement
      :show="showAddSupplementsDialog"
      :day="now(offset)"
      @cancel="showAddSupplementsDialog = false"
      @done="showAddSupplementsDialog = false"
    />
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const AddMealDialog = () => import('@/components/dialogs/AddMeal.vue')
const Activities = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Activities.vue')

const MyPieChart = () =>
  import(/* webpackPrefetch: true */ '@/components/charts/MyPieChart.vue')
const WeightPrompt = () => import('@/components/dialogs/WeightPrompt.vue')
const FoodDetails = () => import('@/components/pageutil/FoodDetails.vue')
const ActivitiesDetails = () =>
  import('@/components/pageutil/ActivitiesDetails.vue')

const AddSupplement = () => import('@/components/dialogs/AddSupplement.vue')
import diets from '@/json/diets.json'
export default {
  name: 'EnergyLog',
  emits: ['done_with_nrg', 'cancel'],
  components: {
    AddMealDialog,
    Activities,
    MyPieChart,
    WeightPrompt,
    FoodDetails,
    ActivitiesDetails,
    AddSupplement
  },
  mixins: [util],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    switchNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      values: [],
      labels: [],
      meals: [],
      activities: [],
      tdee: null,
      energyIn: null,
      energyOut: null,
      showActivitiesDialog: false,
      showActivitiesDetailsDialog: false,
      showFoodDetailsDialog: false,
      showAddMealDialog: false,
      showAddSupplementsDialog: false,
      showWeightPrompt: false,
      selectedid: null,
      offset: 0,
      diff: 0,
      currentPercentage: 0,
      totalOut: 0,
      totalIn: 0,
      switchnum: 0,
      loaded: false,
      diets: diets,
      spi: null,
      foodGauge: null,
      chartdata: {},
      options: {},
      matches: []
    }
  },
  async beforeMount() {
    this.foodGauge = await require('@/assets/foodgauge-new.png')
    this.api = axios.create({
      baseURL: this.baseURL,
      timeout: this.reqTimeout
    })
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  computed: {
    ...appConfig
  },
  watch: {
    show(value) {
      this.$store.dispatch('session/toggleModal', value)
      if (value) {
        this.reloadDaily()
      } else this.reset()
    },
    showActivitiesDetailsDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showFoodDetailsDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showAddMealDialog) this.showAddMealDialog = false
        else if (this.showActivitiesDialog) this.showActivitiesDialog = false
        else if (this.showFoodDetailsDialog) this.showFoodDetailsDialog = false
        else if (this.showActivitiesDetailsDialog)
          this.showActivitiesDetailsDialog = false
        else if (this.show) this.doneAdding()
      }
    
    },
    showDailyMeals() {
      this.showFoodDetailsDialog = true
    },
    showDailyActivities() {
      this.showActivitiesDetailsDialog = true
    },
    calculateBalance() {
      let energyIn = 0
      let energyOut = 0
      if (this.energyIn) energyIn = parseInt(this.energyIn.calories)
      if (this.energyOut) energyOut = parseInt(this.energyOut.calories_total)

      this.totalOut = energyOut + parseInt(this.tdee.metric)
      this.totalIn = energyIn
      this.currentPercentage = Math.round((this.totalIn / this.totalOut) * 100)
      this.diff = this.totalIn - this.totalOut

      this.matches = this.dietMatches(
        this.energyIn.percentage_carbs,
        this.energyIn.percentage_fats,
        this.energyIn.percentage_proteins,
        this.energyIn.carbs,
        this.diets
      )
    },
    done() {
      this.reloadDaily()
    },
    doneAdding() {
      this.updateDayTotals()
      this.$emit('done_with_nrg')
      this.loaded = true
    },
    refreshPage() {
      this.loadMeals()
      this.loadActivities()
      this.reloadDaily()
    },
    async reloadDaily() {
      this.showAddMealDialog = false
      this.showActivitiesDialog = false
      await this.loadTdee()
      await this.loadDailyEnergyOut()
      this.updateDayTotals()
    },
    reset() {
      this.loaded = false
      this.offset = 0
      this.energyIn = null
      this.energyOut = null
      this.tdee = null
      this.diff = 0
      this.matches = null
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    previousDay() {
      this.offset--
      this.loadDailyEnergyOut()
    },
    nextDay() {
      this.offset++
      this.loadDailyEnergyOut()
    },
    deleteMeal(item) {
      return axios
        .get(this.baseURL + '/nutrition/meal/' + item.id, {})
        .then(response => {
          if (response.status == 200) {
            this.loadMeals()
            this.toast('Meal entry was succesfully deleted')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async updateDayTotals() {
      const { metric } = this.tdee || {}
      const {
        calories_total: caloriesOutTotal = 0,
        calories_per_minute: caloriesPerMinute = 0,
        distance_imperial: distanceImperial = 0,
        distance_metric: distanceMetric = 0,
        duration = 0
      } = this.energyOut || {}

      try {
        const response = await this.api.post('/nutrition/day', {
          meal_date: this.now(this.offset, true),
          tdee: metric,
          diff: this.diff,
          calories_out: caloriesOutTotal,
          calories_per_minute: caloriesPerMinute,
          distance_imperial: distanceImperial,
          distance_metric: distanceMetric,
          duration: duration
        })
        if (response.status !== 200) {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        null
      }
    },
    loadTdee() {
      this.api
        .get('/nutrition/tdee')
        .then(response => {
          if (response.status == 200) {
            this.tdee = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
          }
        })
        .catch(() => {
          null
        })
    },

    async loadActivities() {
      try {
        const response = await this.api.get(
          `/nutrition/activities/${this.now(this.offset, true)}`
        )
        if (response.status === 200) {
          this.activities = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      } catch (error) {
        null
      }
    },
    async loadMeals() {
      try {
        const response = await this.api.get(
          `/nutrition/meals/${this.now(this.offset, true)}`
        )
        if (response.status === 200) {
          this.meals = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      } catch (error) {
        null
      }
    },
    async preload() {
      await this.loadTdee()
      await this.loadActivities()
      this.loadMeals()
    },
    async loadDailyEnergyIn() {
      try {
        this.loaded = false
        const response = await this.api.get(
          `/nutrition/calories/in/${this.now(this.offset, true)}`
        )
        if (response.status === 200) {
          this.energyIn = response.data.data
          await this.preload()
          this.calculateBalance()
          if (this.energyIn) {
            this.values = [
              this.energyIn.percentage_fats,
              this.energyIn.percentage_proteins,
              this.energyIn.percentage_carbs
            ]
          }
          this.labels = [
            `Fat ${this.oneDigit(this.nvl(this.energyIn.percentage_fats), 0)}%`,
            `Protein ${this.oneDigit(this.nvl(this.energyIn.percentage_proteins, 0))}%`,
            `Carbs ${this.oneDigit(this.nvl(this.energyIn.percentage_carbs, 0))}%`
          ]
          this.chartdata = {
            labels: this.labels,
            datasets: [
              {
                label: 'Your Plate',
                backgroundColor: ['#800080', '#8BC34A', '#D4AF37'],
                hoverBackgroundColor: ['#fffff', '#fffff', '#fffff'],
                borderColor: '#212b31',
                hoverBorderColor: '#eaeaea',
                borderWidth: 0,
                data: this.values
              }
            ]
          }
          this.options = {
            responsive: false,
            maintainAspectRatio: true,
            legend: {
              labels: {
                display: true,
                boxWidth: 30,
                usePointStyle: false,
                fontColor: '#eaeaea'
              }
            },
            hoverBorderWidth: 20,
            tooltips: {
              enabled: true
            },
            events: []
          }
          this.switchnum++
          this.loaded = true
        } else {
          this.loaded = true
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        this.loaded = true
        null
      }
    },
    async loadDailyEnergyOut() {
      try {
        const response = await this.api.get(
          `/nutrition/calories/out/${this.now(this.offset, true)}`
        )
        if (response.status === 200) {
          this.energyOut = response.data.data
          this.loadDailyEnergyIn()
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        null
      }
    }
  }
}
</script>
